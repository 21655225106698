import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import { Email, GitHub, LinkedIn, Twitter } from '@mui/icons-material';

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
  typography: {
    fontFamily: 'Montserrat, Arial',
  },
});

const IconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  '& > *': {
    margin: theme.spacing(1),
    fontSize: '2rem',
  },
});

const StyledCard = styled(Card)({
  boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
  borderRadius: '15px',
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="md">
        <Typography variant="h2" align="center" color="primary" gutterBottom>
          Hello, I'm Ayush Bhattarai
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          I'm a backend developer specializing in efficient and scalable server-side applications.
        </Typography>
        <IconContainer>
          <GitHub onClick={() => window.open('https://github.com/yourgithub', '_blank')} />
          <LinkedIn onClick={() => window.open('https://www.linkedin.com/in/yourlinkedin/', '_blank')} />
          <Twitter onClick={() => window.open('https://twitter.com/yourtwitter', '_blank')} />
          <Email onClick={() => window.open('mailto:youremail@example.com', '_blank')} />
        </IconContainer>
        <Typography variant="h4" align="center" color="textSecondary" gutterBottom style={{ marginTop: '2rem' }}>
          Projects
        </Typography>
        <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
            <StyledCard onClick={() => window.open('https://github.com/aaxyat/LinkSquasher', '_blank')}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="140"
                  image="/linksquasher.png" // replace with your project image
                  alt="LinkSquasher"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    LinkSquasher
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    LinkSquasher is a URL shortening service that's as straightforward as it is powerful. Easily deployed as a static site, you can manage your short links with a simple JSON file.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <StyledCard onClick={() => window.open('https://github.com/aaxyat/Tomato-Sorter-EB', '_blank')}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="140"
                  image="/theripetomato.png" // replace with your project image
                  alt="The Ripe Tomato"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    The Ripe Tomato
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    This is a machine learning based web application that helps to predict whether a tomato is ripe or unripe. It utilizes a model trained on Keras and provides an easy-to-use interface for uploading a tomato image and receiving the prediction.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <StyledCard onClick={() => window.open('https://github.com/aaxyat/SudokuSolver', '_blank')}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="140"
                  image="/sudokusolver.png" // replace with your project image
                  alt="SudokuSolver"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    SudokuSolver
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    SudokuSolver is a web application that solves Sudoku puzzles. It uses a backtracking algorithm to find the solution and provides a user-friendly interface for inputting the puzzle.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </StyledCard>
          </Grid>
        </Grid>
        <Box mt={5} py={1} bgcolor="primary.main" color="white" textAlign="center">
          <Typography variant="body2">© Ayush Bhattarai, 2023</Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default App;
